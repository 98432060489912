<template>
   <div class="mobile_box">
	    <router-view />
   </div>
</template>

<script>
import zh from './lang/zh.js'
import en from './lang/en.js'
import zh_tw from './lang/zh_tw.js'
import hi from './lang/hi.js'
import id from './lang/id.js'
import jp from './lang/jp.js'
import ko from './lang/ko.js'
import pt from './lang/pt.js'
import vn from './lang/vn.js'
import gr from './lang/gr.js'
import {mapMutations, mapActions} from 'vuex'
import router from './router/index.js';
export default {
	data(){
		
	},
	async created() {
		let langList = {
			'zh':zh,
			'en':en,
			'zh_tw':zh_tw,
			'hi':hi,
			'id':id,
			'jp':jp,
			'ko':ko,
			'pt':pt,
			'vn':vn,
			'gr':gr
		}
		let lang = localStorage.getItem("lang") || 'en'
		localStorage.setItem('lang',lang)
		this.setLang(langList[lang])
		let token = localStorage.getItem("token");
		if(token){
			await this.getUserinfo()
			this.$isResolve()
		}else{
			this.$isResolve()
		}
		//console.log(this.$store.state,'state');
	},
	methods:{
		...mapMutations(['setLang']),
		...mapActions(['getUserinfo'])
	}
};
</script>

<style>
@import "./assets/css/main.css";
.mobile_box{
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}
.el-tabs.el-tabs--top{
	width: 100%;
	overflow:hidden;
}
.qaq .el-collapse-item__header{
	line-height: 20px!important;
}
.bank_card .el-input.is-disabled .el-input__inner{
	color: #333!important;
}
</style>
